import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import { motion } from "framer-motion"
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import BlogPostPreviewList from '../../components/blog-post-preview-list'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop } from '../../containers'
import Hero from '../../components/hero'
import PortableText from '../../components/portableText'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
// import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import {
  PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell, Sector
} from 'recharts';
import { DiscussionEmbed } from 'disqus-react'
import { MdBrightness1, MdRemoveCircleOutline, MdAdd, MdHelp } from "react-icons/md";
import ReactTooltip from 'react-tooltip';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import houseHackingImg from '../../assets/house-hacking-2x2.png'
import Sidebar from '../../components/Sidebar'
import randomColor from 'randomcolor'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query PortfolioRebalancingCalcPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      metaImage {
        ...SanityImage
        alt
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
          fixed(width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          authors{
            author{
              name
              id
            }
          }
          mainImage {
            asset{
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 400) {
                ...GatsbySanityImageFixed
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const H2 = styled.h2`
  font-weight: 300;
`

const CalculatorPanel = styled.div`
  margin: 0 auto;
  /* padding: 24px; */
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  max-width: 1400px;

  @media (max-width: 500px) {
    border: none;
    border-radius: 0px;
  }
`

const CalculatorGrid = styled.div`
  padding: 24px;
  margin: 0 auto;
  box-sizing: border-box;

  /* margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px; */
  display: grid;
  /* grid-template-columns: minmax(300px, 1fr) 3fr; */
  grid-template-columns: 1fr;
  grid-gap: 48px;
  align-items: start;
  margin-bottom: 48px;
  /* max-width: 1200px; */

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }

  h3 {
    text-transform: uppercase;
  }
`

const GraphDesktop = styled.div`
  display: flex;

  @media (max-width: 600px) {
    display: none;
  }
`

const GraphMobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 0px 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }
  
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 0px 0;
    background-color: ${props => props.theme.theme.formFill.bgReadOnly};
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.borderReadOnly};
    }
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.borderReadOnly};
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.borderReadOnly};
  }
`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }
`

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`

const GroupLabel = styled.h3`
  display: block;
  width: 100%;
  color: ${(props) => props.theme.theme.text.primary};
  text-transform: uppercase;
  margin: 0 0 24px 0;
  font-weight: 500;
  font-size: 0.9rem;
`

const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

const FlexInput = styled.div`
  margin: 0px 12px 12px 0;
  width: 100%;
`;

const InputItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 4px;
  align-items: center;
  margin: 0 0 0 0;
`;

const AllocationGroup = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  margin: 0 0 12px 0;
  width: 100%;

  h3 {
    text-transform: uppercase;
    margin: 0 0 8px 0;
    font-weight: 500;
  }
`;

const AllocationGroupGray = styled(AllocationGroup)`
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  color: ${(props) => props.theme.theme.text.primary};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.border.tertiary};
  }
`;

const AllocationGroupRowTwoCol = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 1fr) 60px;
  grid-gap: 18px;
`;

const AllocationGroupRed = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentRed};
  border: 1px solid ${(props) => props.theme.theme.colors.red};
  color: ${(props) => props.theme.theme.colors.red};
  /* margin: 8px 8px 0 8px; */

  .result-value {
    font-weight: 500;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.red};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.red};
  }
`;

const AllocationGroupGreen = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  color: ${(props) => props.theme.theme.colors.green};
  /* margin: 8px 8px 0 8px; */

  .result-value {
    font-weight: 500;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.green};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.green};
  }
`;

const SliderBlack = styled(Slider)`
  &.MuiSlider-root {
    color: ${(props) => props.theme.theme.colors.black};
  }
`;

const InputGroup = styled.div`
  box-sizing: border-box;
  display: grid;
  align-content: start;
  /* display: flex; */
  flex-wrap: wrap;
  /* padding: 8px 8px 0 8px; */
  /* border-radius: 8px; */
  /* padding: 12px 12px 0px 12px; */
  /* border: 1px solid ${(props) => props.theme.theme.border.tertiary}; */
  grid-template-columns: 1fr;
  /* grid-gap: 24px; */
  margin: 0 0 0px 0;

  /* h3 {
    color: ${(props) => props.theme.theme.text.primary};
    text-transform: uppercase;
    margin: 0 0 12px 0;
    font-weight: 500;
  } */

  @media (max-width: 500px) {
    /* padding: 24px 0px 0 0px; */
  }
`;

const CustomReactTooltip = styled(ReactTooltip)`
  max-width: 50vw;
`;

const ResultsPanel = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  padding: 12px;
  display: flex;
  flex-wrap: wrap;

  .label {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 8px 0 0;
  }

  .result-value {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 4px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 12px 0 0;
  }

  h3 {
    color: ${(props) => props.theme.theme.colors.green};
  }

  @media (max-width: 500px) {
    margin: 0px;
  }
`;

const ResultsGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;

  @media (max-width: 500px) {
  }
`;

const FinancesTableWrapper = styled.div`
  overflow-x: scroll;
  border: 1px solid ${(props) => props.theme.theme.border.secondary};

  @media (max-width: 500px) {
    max-width: 90vw;
  }
`;
const FinancesTableGrid = styled.div`
  display: grid;
  margin: 0 auto;
`;

const FinancesTableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 150px);
  justify-items: center;
  padding: 4px;
  grid-gap: 16px;
  border-top: 1px solid ${(props) => props.theme.theme.border.secondary};

  &.labels-row {
    font-weight: 600;
    border: none;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(5, 85px);
  }
`;

const ColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) ) ;
  grid-gap: 24px;
`

const ContainerContent = styled.div`
  max-width: ${props => props.theme.theme.contentWidths.content};
`

const ContainerTwoCol = styled(ContainerMain)`
  display: grid;
  grid-template-columns: ${props => props.theme.theme.contentWidths.content} 1fr;
  grid-gap: 48px;
  padding-right: 0px;
  align-items: start;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    padding-right: 24px;
  }
`

const CalculatorChart = styled.div`
  display: grid;
  width: 100%;
  margin: 0 auto 0 0;
  padding: 8px;
  box-sizing: border-box;
  overflow-x: scroll;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 8px;
  justify-content: start;

  @media (max-width: 1200px) {
    width: 90vw;
  }
`

const CalculatorColumn = styled.div`
  display: grid;
  grid-template-columns: 30px 300px 150px 150px 150px 150px;
  align-items: center;
  justify-items: left;
  grid-gap: 24px;
  margin: 0 0 12px 0;

  h4 {
    margin: 0;
    text-transform: uppercase;
  }
`

export const Button = styled(motion.button)`
  display: flex;
  border: 0;
  font-size: 1rem;
  font-weight: 500;
  padding: 5px 10px;
  align-items: center;
  border-radius: 5px;
  height: 40px;
  /* text-transform: uppercase; */
  &:hover {
    cursor: pointer;
  }
  &:focus {outline:0;}
  svg{
    margin: 0 0 0 0px;
  }
`

const ButtonPrimary = styled(Button)`
  color: ${props => props.theme.theme.colors.white};
  background-color: ${(props) => props.theme.theme.colors.green};
  margin: 0 12px 0 0;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
`

const Image = styled.img`
  width: 100%;
`

const ChartsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const PieItem = styled.div`
  display: grid;
  justify-items: center;

  h4{

  }

`

const CustomTooltip = (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    console.log(payload)
    console.log(label)

    return(
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Month #: </strong>
          {payload[0].payload.month}
        </CardText400>

        <CardText400>
          <strong>Monthly payment: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.monthlyPayment.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Payment towards interest: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.interestPortion.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Payment towards principal: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.principalPortion.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Loan Balance: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.loanBalance.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    )
  }
  return(null)

}

function NumberFormatMonths(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

NumberFormatMonths.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix=" %"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const PortfolioRebalancingCalc = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const posts = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const [ rebalancingDuration, setRebalancingDuration ] = useState(3)
  const [ portfolioItems, setPortfolioItems ] = useState([
    {
      name: "Stocks",
      targetAllocation: 60,
      targetAllocationShown: 60,
      currentAmount: 50000,
      currentAmountShown: 50000,
      color: '#3AC732'
    },
    {
      name: "Real Estate",
      targetAllocation: 20,
      targetAllocationShown: 20,
      currentAmount: 20000,
      currentAmountShown: 20000,
      color: '#2196F3'
    },
    {
      name: "Cash",
      targetAllocation: 20,
      targetAllocationShown: 20,
      currentAmount: 30000,
      currentAmountShown: 30000,
      color: '#E90C35'
    }
  ])

  const updateTextFieldChanged = (index, property) => event => {
    // console.log(index)
    console.log(event.target.value)
    let newArr = [...portfolioItems] //copying the existing portfolio array
    newArr[index][property] = (event.target.value)

    setPortfolioItems(newArr)
    console.log(portfolioItems)
  }

  const updateFieldChanged = (index, property) => event => {
    // console.log(index)
    console.log(event.target.value)
    let newArr = [...portfolioItems] //copying the existing portfolio array
    newArr[index][property] = parseFloat(event.target.value) ? parseFloat(event.target.value) : 0
    newArr[index][`${property}Shown`] = parseFloat(event.target.value)

    setPortfolioItems(newArr)
    console.log(portfolioItems)
  }

  const allocationTotal = portfolioItems.reduce( (prev, next) => {
    return parseFloat(prev) + parseFloat(next.targetAllocation)
  }, 0)

  const currentAmountTotal = portfolioItems.reduce( (prev, next) => {
    return parseFloat(prev) + parseFloat(next.currentAmount)
  }, 0)

  const derivedPortfolioItems = portfolioItems.map( item => {
    return({
      targetAmount: (item.targetAllocation/100) * currentAmountTotal
    })
  })

  const addAsset = (e) => {
    e.preventDefault();
    console.log("button clicked")
    let newColor = randomColor()
    let newArr = [...portfolioItems]
    newArr.push({
      name: "",
      targetAllocation: 0,
      currentAmount: 0,
      color: newColor
    })

    setPortfolioItems(newArr)
  }

  const removeAsset = (e, i) => {
    console.log(`remove asset clicked ${i}`)
    e.preventDefault();
    let newArr = [...portfolioItems]
    newArr.splice(i, 1)
    console.log(newArr)
    setPortfolioItems(newArr)
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

  const currentPortfoliolabelHelper = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    index
  }) => {
    console.log("handling label?");
    const RADIAN = Math.PI / 180;
    // eslint-disable-next-line
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    // eslint-disable-next-line
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    // eslint-disable-next-line
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={portfolioItems[index].color}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        ${numberWithCommas(portfolioItems[index].currentAmount)}
      </text>
    );
  }

  const targetPortfoliolabelHelper = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    index
  }) => {
    console.log("handling label?");
    const RADIAN = Math.PI / 180;
    // eslint-disable-next-line
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    // eslint-disable-next-line
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    // eslint-disable-next-line
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={portfolioItems[index].color}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        ${numberWithCommas(derivedPortfolioItems[index].targetAmount)}
      </text>
    );
  }

  return (
    <Layout>
      <SEO
        title={'Portfolio Rebalancing Calculator'}
        description={'Calculate how to rebalance your current portfolio to achieve your target asset allocations'}
        keywords={site.keywords}
      />
      <CustomReactTooltip />
      <ContainerMain>
        <h1>Portfolio Rebalancing Calculator</h1>
        <H2>Calculate how to rebalance your current portfolio to achieve your target asset allocations</H2>

      </ContainerMain>

      <CalculatorPanel>
        <CalculatorGrid>
          <form>
            <h3>Current Portfolio Allocations</h3>
            <CalculatorChart>
              <CalculatorColumn>
                <div />
                <h4>Asset Name</h4>
                <h4>Target Allocation</h4>
                <h4>Current Amount</h4>
                <h4>Target Amount</h4>
              </CalculatorColumn>
              {portfolioItems.map( (elem, i) => {
                console.log(`elem.name: ${elem.name}`)
                return(
                  <CalculatorColumn>
                    <MdBrightness1 color={elem.color} size="30" />
                    <ThemedTextarea
                      required
                      id={`portfolio-item-name-${i}`}
                      label=""
                      value={elem.name}
                      onChange={updateTextFieldChanged(i, 'name')}
                      variant="outlined"
                      fullWidth
                    />
                    <ThemedTextarea
                      required
                      id={`portfolio-item-targetAllocation-${i}`}
                      label=""
                      value={elem.targetAllocationShown}
                      onChange={updateFieldChanged(i, 'targetAllocation')}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatPercentage,
                      }}
                    />
                    <ThemedTextarea
                      required
                      id={`portfolio-item-currentAmount-${i}`}
                      label=""
                      value={elem.currentAmountShown}
                      onChange={updateFieldChanged(i, 'currentAmount')}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                    <ThemedTextareaPrimary
                      id="outlined-read-only-input"
                      label=""
                      value={currentAmountTotal*(elem.targetAllocation/100)}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        readOnly: true
                      }}
                    />
                    <MdRemoveCircleOutline size="30px" onClick={e => removeAsset(e, i)} />
                  </CalculatorColumn>
                )
              }
              )}
            <CalculatorColumn>
              <div />
              <h4>Total:</h4>
              <ThemedTextareaPrimary
                id="outlined-read-only-input"
                label=""
                value={allocationTotal}
                variant="outlined"
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatPercentage,
                  readOnly: true
                }}
              />
              <ThemedTextareaPrimary
                id="outlined-read-only-input"
                label=""
                value={currentAmountTotal}
                variant="outlined"
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  readOnly: true
                }}
              />
              <ThemedTextareaPrimary
                id="outlined-read-only-input"
                label=""
                value={currentAmountTotal}
                variant="outlined"
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  readOnly: true
                }}
              />
              {/* <button
                onClick={addAsset}
              >Add asset</button> */}
              <ButtonPrimary
                whileHover={{
                  scale: 1.02,
                }}
                onClick={addAsset} >
                  <MdAdd size="20" />
                  Add Asset
              </ButtonPrimary>
            </CalculatorColumn>
          </CalculatorChart>

          <ChartsContainer>

          <GraphDesktop>
            <PieItem>
              <h4>Current Portfolio</h4>
              <PieChart width={400} height={300}>
                <Pie
                  dataKey="currentAmount"
                  isAnimationActive={false}
                  data={portfolioItems}
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label={currentPortfoliolabelHelper}
                >
                  {portfolioItems.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                {/* <Pie dataKey="currentAmount" data={data02} cx={500} cy={200} innerRadius={40} outerRadius={80} fill="#82ca9d" /> */}
                {/* <Tooltip /> */}
              </PieChart>
            </PieItem>

            <PieItem>
              <h4>Target Portfolio</h4>
              <PieChart width={400} height={300}>
                <Pie
                  dataKey="targetAmount"
                  isAnimationActive={false}
                  data={derivedPortfolioItems}
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label={targetPortfoliolabelHelper}
                >
                  {portfolioItems.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                {/* <Pie dataKey="currentAmount" data={data02} cx={500} cy={200} innerRadius={40} outerRadius={80} fill="#82ca9d" /> */}
                {/* <Tooltip /> */}
              </PieChart>
            </PieItem>
          </GraphDesktop>

          <GraphMobile>
            <PieItem>
              <h4>Current Portfolio</h4>
              <PieChart width={300} height={300}>
                <Pie
                  dataKey="currentAmount"
                  isAnimationActive={false}
                  data={portfolioItems}
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label={currentPortfoliolabelHelper}
                >
                  {portfolioItems.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                {/* <Pie dataKey="currentAmount" data={data02} cx={500} cy={200} innerRadius={40} outerRadius={80} fill="#82ca9d" /> */}
                {/* <Tooltip /> */}
              </PieChart>
            </PieItem>

            <PieItem>
              <h4>Target Portfolio</h4>
              <PieChart width={300} height={300}>
                <Pie
                  dataKey="targetAmount"
                  isAnimationActive={false}
                  data={derivedPortfolioItems}
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label={targetPortfoliolabelHelper}
                >
                  {portfolioItems.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                {/* <Pie dataKey="currentAmount" data={data02} cx={500} cy={200} innerRadius={40} outerRadius={80} fill="#82ca9d" /> */}
                {/* <Tooltip /> */}
              </PieChart>
            </PieItem>
          </GraphMobile>

          </ChartsContainer>

          <FlexInput style={{ width: "300px", margin: "0 0 36px 0" }}>
            <InputItem>
              <ThemedTextarea
                  id="rebalancing-period-input"
                  label="Rebalancing Period (Months)"
                  value={rebalancingDuration}
                  onChange={event => setRebalancingDuration(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatMonths,
                  }}
                />
              <MdHelp
                size="1.2rem"
                data-tip={`This is the amount of time (in months) over which you plan to rebalance your portfolio.`}
              />
            </InputItem>
          </FlexInput>

          <h3>Rebalancing Inflows</h3>

          <CalculatorChart>
              <CalculatorColumn>
                <div />
                <h4>Asset Name</h4>
                <h4>Current Amount</h4>
                <h4>Target Amount</h4>
                <h4>Monthly inflow</h4>
              </CalculatorColumn>
              {portfolioItems.map( (elem, i) => {
                console.log(`elem.name: ${elem.name}`)
                return(
                  <CalculatorColumn>
                    <MdBrightness1 color={elem.color} size="30" />
                    <ThemedTextareaPrimary
                      required
                      id={`portfolio-item-name-${i}`}
                      label=""
                      value={elem.name}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    <ThemedTextareaPrimary
                      required
                      id={`portfolio-item-targetAllocation-${i}`}
                      label=""
                      value={elem.currentAmountShown}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        readOnly: true
                      }}
                    />
                    <ThemedTextareaPrimary
                      required
                      id={`portfolio-item-currentAmount-${i}`}
                      label=""
                      value={currentAmountTotal*(elem.targetAllocation/100)}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        readOnly: true
                      }}
                    />
                    <ThemedTextareaPrimary
                      id="outlined-read-only-input"
                      label=""
                      value={ rebalancingDuration ? ((currentAmountTotal*(elem.targetAllocation/100) - elem.currentAmountShown) / rebalancingDuration).toFixed(0) : 0}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        readOnly: true
                      }}
                    />
                  </CalculatorColumn>
                )
              }
              )}
          </CalculatorChart>

          </form>


          {/* <ResponsiveContainer width="100%" height="100%"> */}
      {/* </ResponsiveContainer> */}

        </CalculatorGrid>

      </CalculatorPanel>

      <ContainerTwoCol>
        <ContainerContent>
          <h2>What is portfolio rebalancing?</h2>
          <p>
            Portfolio rebalancing is the process of adjusting the allocations of assets currently in your portfolio to maintain a desired allocation of assets.
            For example, you may begin with a portfolio that is comprised of 65% stocks, 20% real estate, 10% cash, and 5% cryptocurrency, which is your target allocation.
            However, over time your crypto outperforms your other assets and grows to be 25% of your portfolio, with your stocks, real estate, and cash making up the
            remaining 75% of your portfolio. In this case, to bring your asset allocations back to your target allocation, you would rebalance your portfolio by selling
            some of your crypto and moving these profits into stocks, real estate, and cash to bring your asset allocations back to your original target portoflio allocation.
          </p>

          <h2>Why should I rebalance my portfolio?</h2>
          <p>
            The main reason that investors use the strategy of portfolio rebalancing is to prevent being overly exposed to undesirable risks.
            By periodically rebalancing their portfolios, investors ensure that they hold an asset mix that matches their risk tolerance.
            Since this strategy entails selling assets that have outperformed in order to buy assets that have underperformed, portfolio
            rebalancing essentially builds the "buy low, sell high" mantra into your investing process automatically.
          </p>

          <h2>When and how do I rebalance my portfolio?</h2>
          <p>
            <a href="https://investor.vanguard.com/investing/portfolio-management/rebalance" target="_blank">Vanguard</a> recommends checking your portfolio at least once per year, and to consider rebalancing if your asset allocation is off your target
            allocation by 5% or greater. Calendar rebalancing is the simplest portfolio rebalancing approach, where you analyze your portfolio holdings at a
            predetermined time interval and then adjust your holdings to match your target over a desired duration of time. This could mean you analyze your
            portfolio annually, biannually, or quarterly and then rebalance your holdings over a period of weeks or months to reach your target allocation.
          </p>

          <p>There are multiple ways that you could rebalance your portfolio:</p>
          <ol>
            <li>
              If you earn income, you could direct your savings into buying more of your assets that are under their target allocation and not into assets that are above their target allocation.
            </li>
            <li>
              You could also move money directly between assets by selling an asset that is above its target allocation in order to buy assets that are below their target allocation.
              It's important to note that if  you are invested in a taxable account, then selling investments that have gained value will incur taxes on your gains.
              To avoid this you could only rebalance within your tax-advantaged accounts like your 401k and IRA.
            </li>
          </ol>

          <h2>How to use this calculator</h2>
          <ul>
            <li>
              In the <strong>Current Portfolio Allocations</strong> chart, enter each of the asset types in your portfolio. You can enter the <strong>Asset Name</strong>, <strong>Target Allocation</strong>, and <strong>Current Amount</strong> of each asset.
            </li>
            <li>
              You can remove an asset from the chart by clicking the minus button on the right side.
            </li>
            <li>
              You can add a new asset by clicking the "Add Asset" button.
            </li>
            <li>
              The last row of the chart shows your totals. Your total <strong>Target Allocation</strong> should be 100%.
            </li>
            <li>
              The two pie charts illustrate your <strong>Current Portfolio</strong> breakdown and your <strong>Target Portfolio</strong> breakdown. The colors correspond to each asset in the asset chart above.
            </li>
            <li>
              Your <strong>Rebalancing Period</strong> is the amount of time in months over which you want to "spread out" your rebalancing. If you to rebalance your portfolio all at once, enter <strong>1</strong> for this input.
              If you wanted to rebalance over a period of say 6 months, then enter <strong>6</strong> in the <strong>Rebalancing Period</strong> input field.
            </li>
            <li>
              The <strong>Rebalancing Inflows</strong> chart is read-only and shows the monthly inflow for each asset that you would need to reach your target portfolio allocation over the <strong>Rebalancing Period</strong> that you input above.
              A positive value for <strong>Monthly Inflow</strong> means that you would be buying more of that asset, and a negative <strong>Monthly Inflow</strong> indicates that you would sell that asset.
            </li>
          </ul>


        </ContainerContent>

        <Sidebar />
      </ContainerTwoCol>

      <Backdrop>
        <ContainerMain>
          <DiscussionEmbed
              shortname={process.env.GATSBY_DISQUS_NAME}
              config={{
                  identifier: 'portfolio-rebalancing-calculator'
              }}
          />
        </ContainerMain>
      </Backdrop>
    </Layout>
  )
}

export default PortfolioRebalancingCalc
